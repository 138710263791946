import Api from "../../services/Api";

const renameProperty = function (object, oldName, newName) {
    // Do nothing if the names are the same
    if (oldName === newName) {
        return this;
    }
    // Check for the old property name to avoid a ReferenceError in strict mode.
    // eslint-disable-next-line no-prototype-builtins
    if (object.hasOwnProperty(oldName)) {
        object[newName] = object[oldName];
        delete object[oldName];
    }
    return object;
};

const state = {
    reports: [],
    currentReport: {},
    showSlip: false,
    currentSlipImage: "",
    reportsMetadata: {},
    filters: {
        report_outcome: {
            values: [],
            options: [{
                label: "Passed",
                value: "passed"
            },
            {
                label: "Failed",
                value: "failed"
            }]
        },
        report_type: {
            values: ["real_report"],
            options: [{
                label: "Real Report",
                value: "real_report",
            },
            {
                label: "Test Report",
                value: "test_report",
            }]
        },
        date_range: {
            values: {
                start: null,
                end: null
            }
        },
        report_contains: {
            values: [],
            options: [{
                label: "Skipped Test",
                value: "skipped"
            },
            {
                label: "Failed Test",
                value: "failed"
            },
            {
                label: "Not Installed Test",
                value: "not_installed"
            },
        ]},
        search_text: {
            values: ""
        },
    },
};

const getters = {
    reports: state => state.reports,
    filters: state => state.filters,
    showSlip: state => state.showSlip,
    currentSlipImage: state => state.currentSlipImage,
    currentReport: (state) => {
        return state.reports.find(report => report.id === state.currentReport);
    }
};

const actions = {
    setSearchQuery({commit}, query) {
        commit("SET_SEARCH_QUERY", query);
    },
    selectReport({commit}, report) {
        commit("SET_CURRENT_REPORT", report);
    },
    hideSlip({commit}) {
        commit("SET_SHOW_SLIP", false);
    },
    getSlip({commit}, id) {
        Api.getSlip(id)
            .then(response => {
                commit("SET_CURRENT_SLIP_IMAGE", URL.createObjectURL(response.data));
                commit("SET_SHOW_SLIP", true);
            })
            .catch(error => {
                console.log(error);
            });
    },
    getReports({rootState, commit}, data) {
        Api.getReports(data, rootState.report.reportsMetadata, rootState.report.filters)
            .then(response => {
                commit('SET_REPORTS_METADATA', {
                    last_page: response.data.last_page,
                    current_page: response.data.page,
                    total: response.data.total
                })
                commit('SET_REPORTS', response.data.reports);
            })
            .catch(error => {
                console.log(error);
            });
    },
    downloadCsvReports() {
        Api.downloadCSV().then(response => {
            alert("Downloaded");
            console.log(response);
        })
    },
    applyFilters({rootState, commit, dispatch}, data) {
        console.log(rootState);
        console.log(data)
        let filtersObject = {...rootState.report.filters}
        console.log(filtersObject);
        data = renameProperty(data, "dates", "date_range");
        let keys = Object.keys(data);
        keys.forEach(key => {
            filtersObject[key].values = data[key];
            if (key === "date_range" && data[key].length === 2) {
                filtersObject[key].values.start = data[key][0];
                filtersObject[key].values.end = data[key][1];
            }
        });
        commit('SET_FILTERS', filtersObject);
        dispatch('getReports', false);
    }
};

const mutations = {
    SET_REPORTS(state, data) {
        if (state.reportsMetadata.current_page === 1) {
            state.reports = data;
        } else {
            state.reports = state.reports.concat(data);
        }
    },
    SET_CURRENT_SLIP_IMAGE(state, data) {
        state.currentSlipImage = data;
    },
    SET_SHOW_SLIP(state, data) {
        state.showSlip = data;
    },
    SET_CURRENT_REPORT(state, data) {
        state.currentReport = data;
    },
    SET_REPORTS_METADATA(state, data) {
        state.reportsMetadata = data;
    },
    SET_FILTERS(state, filters) {
        state.filters = filters;
    },
    SET_SEARCH_QUERY(state, query) {
        state.filters.device_id.value = query;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
