import axios from 'axios';
import router from '../router';
import { TokenStorage } from './TokenStorage';

const axiosApiInstance = axios.create()
axiosApiInstance.defaults.baseURL = process.env.VUE_APP_API_URL;
axiosApiInstance.interceptors.request.use((config) => {
  const auth = TokenStorage.isAuthenticated();
  if (!auth && config.url !== '/login') {
    router.replace({ name: 'Login' })
    throw new axios.Cancel("Missing auth");
  }
  config.headers = TokenStorage.getAuthentication().headers;
  return config;
})
axiosApiInstance.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response === "Token is expired") {
    
    if (error.response.status === 500) {
          TokenStorage.clear();
          router.push({ name: 'Login' })
          return new Promise((resolve, reject) => {
            reject(error);
      }).catch(error => {
        Promise.reject(error);
      }).finally(() => {
        window.location.reload();
      })
    }
    if (error.config.url === '/refresh') {
      TokenStorage.clear();
      router.push({ name: 'Login' })
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  } else {
    TokenStorage.clear();
    router.push({ name: 'Login' })
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

})
export default {
  login(data) {
    return axiosApiInstance.post(`/login`, {
      "token": data
    })
  },
  getSlip(id) {
    return axiosApiInstance.get(`/slip?id=${id}`, {responseType: "blob"})
  },
  getReports(flags, state, filters) {
    let filterParams = {

    };
    let availableFilters = Object.keys(filters);
    availableFilters.forEach(filter => {
      if (filters[filter].values && filters[filter].values.length > 0) {
        filterParams[filter] = filters[filter].values;
      } else {
        console.log(filters[filter])
        filterParams[filter] = filters[filter].value;
      }
    })
    if (flags.next) {
      if (state.current_page < state.last_page) {
        return axiosApiInstance.post("/reports", {
            page: state.current_page + 1,
            ...filterParams
        })
      } else {
        return new Promise((resolve, reject) => {
          reject(new Error("No more pages"));
        })
      }
    }else {
      return axiosApiInstance.post("/reports", {
          ...filterParams
      });
    }
  },
  refresh() {
    return axiosApiInstance.post(`/refresh`)
  },
  search(query) {
    return axiosApiInstance.get(`/reports/?id=${query}`)
  },
  getHardware(value){
    return axiosApiInstance.get(`/hardware/${value}/summary`)
  },
  getCurrentHardware(route, name){
    return axiosApiInstance.get(`/hardware/${route}?model_name=${name}`)
  },
  searchHardware(hardware, query) {
    return axiosApiInstance.get(`/hardware/${hardware}/summary?model_query=${query}`)
  },
  getCurrentHardwarePeriod(route, name, date){
    return axiosApiInstance.get(`/hardware/${route}?model_name=${name}&interval=${date}`)
  },
 downloadCSV() {
    axiosApiInstance
      .get(`/reports_csv`, { responseType: "text" })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let date = new Date();
        let timestamp = date.getDate() + "-"+ (date.getMonth() + 1) + "-"  + date.getFullYear() + "_" + date.getHours() + "-" + date.getMinutes() + "-" + date.getSeconds();
        //convert to unix timestamp
        link.setAttribute('download', `reports_exports_${timestamp}.csv`); // specify the filename
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        console.error('Failed to download CSV file:', error);
      });
  }
}
